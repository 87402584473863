$pure-dark-half: hsla(0, 0%, 0%, .5);

@import "./utilities/root";
@import "./utilities/snackbars";

@import "./commons/override-variables";
@import "./commons/font-face";
@import "./commons/bootstrap-imports";

@font-face {
  font-family: 'Roboto', sans-serif;
  font-display: swap;
}

/* fixes scrollbar repositioning page */
html {
  overflow-x: hidden;
  margin-right: calc(-1 * (100vw - 100%));
}

/* fixes mobile minimum width */
body, .modal {
  min-width: 310px;
}

body {
  min-height: 100vh;
  position: relative;
  margin: 0;

  background-color: var(--background);
  color: var(--font-color);
}

.justify-content-center {
  justify-content: center !important;
}

//todo to remove

.navbar-brand {
  margin-right: 0;
  flex: 1 1;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.downloads-container {
  scrollbar-width: thin; /* firefox property */
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 75vh;
}

.downloads-container::-webkit-scrollbar {
  height: 6px;
  width: 6px;
  background: #f0f0f0;
}

.downloads-container::-webkit-scrollbar-thumb {
  background: #cdcdcd;
}


.vertically-center-error {
  position: relative;
  top: 25vh;
}

@media (max-width: 992px) {
  .vertically-center-error {
    top: 10vh;
  }
}

.btn {
  text-transform: none !important;
}

.dropdown-menu a {
  font-size: 14px !important;
  padding: 4px 10px !important;
}

.dropdown-menu {
  padding: .2rem 0;
}

.markdown-box p, ul {
  margin-bottom: 0 !important;
}

.text-black-75 {
  color: hsla(0, 0%, 95%, .75);
}

.rounded-top-0 {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}