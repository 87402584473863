:root {
  --font-size: 0.9375rem;
  --border-radius: .35rem;
  --box-shadow: none;
  --background: hsl(217, 11%, 14%);
  --secondary: hsl(214, 8%, 17%);
  --tertiary: hsl(214, 7%, 19%);
  --quaternary: hsl(213, 7%, 24%);
  --disabled: hsl(243, 17%, 21%);
  --hover: hsl(219, 11%, 32%);
  --internal: hsl(217, 11%, 16%);
  --font-color: hsl(0, 0%, 95%);

  --hover-transition: all .2s ease-in-out;
  --hover-transform-scale-sm: scale(1.05);
  --hover-transform-scale-lg: scale(1.2);
  --pure-dark-half: hsla(0, 0%, 0%, .5);
}